import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faImage,
  faTrash,
  faFilePdf,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/DropZoneInput.module.css'

const DropZoneInput = ({ onFileAccepted }) => {
  const [fileInfo, setFileInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
    },
    maxSize: 3145728, // 3MB
    maxFiles: 1,
    multiple: false,
    onDropAccepted: (acceptedFiles) => {
      setIsLoading(true)
      setError('')
      const file = acceptedFiles[0]
      if (file) {
        const fileType = file.type
        if (fileType.startsWith('image/')) {
          const previewUrl = URL.createObjectURL(file)
          setFileInfo({ type: fileType, name: file.name, previewUrl })
        } else {
          setFileInfo({ type: fileType, name: file.name })
        }
        onFileAccepted(file) // Call the handler passed from ChatWindow
      }
      setIsLoading(false)
    },
    onDropRejected: () => {
      setError('File type not supported or file is too large.')
      setIsLoading(false)
    },
  })

  useEffect(() => {
    // Cleanup the URL object when the component unmounts or the file changes
    return () => {
      if (fileInfo?.previewUrl) {
        URL.revokeObjectURL(fileInfo.previewUrl)
      }
    }
  }, [fileInfo])

  const handleRemoveFile = (event) => {
    event.stopPropagation()
    if (fileInfo?.previewUrl) {
      URL.revokeObjectURL(fileInfo.previewUrl)
    }
    setFileInfo(null)
    setError('')
    setIsLoading(false)
    onFileAccepted(null)
  }

  return (
    <div
      {...getRootProps({
        className: `${styles.container} dropzone`,
      })}
    >
      <input {...getInputProps()} />

      {!fileInfo && (
        <FontAwesomeIcon icon={faFileImport} className={styles.icon} />
      )}

      {isLoading && <p>Loading...</p>}
      {error && <p className={styles.text}>{error}</p>}
      {fileInfo && (
        <div className={styles.filePreview}>
          <FontAwesomeIcon
            icon={faTrash}
            className={`${styles.icon} ${styles.floatingIcon}`}
            onClick={handleRemoveFile}
          />
          {fileInfo.type.startsWith('image/') && fileInfo.previewUrl && (
            <img
              src={fileInfo.previewUrl}
              alt="Preview"
              className={styles.picture}
            />
          )}
          {fileInfo.type === 'application/pdf' && (
            <div className={styles.pdfPreview}>
              <FontAwesomeIcon
                icon={faFilePdf}
                className={styles.pdfIcon}
                style={{ color: 'white' }}
              />
              <p className={styles.fileName}>{fileInfo.name}</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DropZoneInput
