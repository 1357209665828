import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import styles from "../../styles/newChatting/ChatWindow.module.css";
import DropZoneInput from "../chatting/DropZoneInput";
import { handleFileUpload, splitter } from "../../utils/Helpers";
import { chatReducer, initialState } from "../../utils/chatReducer";
import { FlowiseClient } from "flowise-sdk";
import {
  getChatMessages,
  updateChatMessages,
  createNewChat,
  sendPromptToChatbot,
  generateTitle,
  getImage,
} from "../../services/API/ChatServices";
import ErrorCard from "../ErrorCard";
import VideoSwitch from "../chatting/VideoSwitch";
import ImageContainer from "../chatting/ImageContainer";
import ReactMarkdown from "react-markdown";
import DOMPurify from "dompurify";
import { v4 as uuidv4 } from "uuid";
import SendButton from "../SendButton";
import LoadingSpinner from "../chatting/LoadingSpinner";
import InteractiveAvatar from "./InteractiveAvatar";
import sessionStorage from "redux-persist/lib/storage/session";

const ChatWindow = ({ chatbotId, chatbotDetails, sessionFlowiseID }) => {
  const client = new FlowiseClient({
    baseUrl: process.env.REACT_APP_FLOWISE_BASE_URL_STREAMING,
  });

  const { chatBotId, conversationId } = useParams();
  const location = useLocation();

  const isNewConversation =
    location.pathname.endsWith("new-conversation") ||
    conversationId === "new-conversation";

  const navigate = useNavigate();

  const imagePathChatbot = chatbotDetails.avatar
    ? `${process.env.PUBLIC_URL}/images/avatars/${chatbotDetails.avatar}.png`
    : "";

  const imagePathUser =
    process.env.PUBLIC_URL + "/images/avatars/avatarUser2.png";

  const newMessagesContainerRef = useRef(null);

  const messagesContainerRef = useRef(null);

  const messageTextAreaRef = useRef(null);

  const videoStreamingRef = useRef(null);

  const currentConversationIdRef = useRef(null);

  useEffect(() => {
    currentConversationIdRef.current = conversationId;
  }, [conversationId]);
  // Add a ref to store InteractiveAvatar's handleSpeak function
  const handleSpeakRef = useRef(null);

  // Callback to receive handleSpeak from InteractiveAvatar
  const registerHandleSpeak = useCallback((handleSpeakFn) => {
    handleSpeakRef.current = handleSpeakFn;
  }, []);

  // Use Reducer for State Management
  const [state, dispatch] = useReducer(chatReducer, initialState);

  // Extract states from reducer
  const {
    chatbotFlowiseID,
    chatbotFlowID,
    isLoading,
    conversation,
    information,
    error,
    videoToogle,
    chatbotTyping,
    fileBase64String,
    temporaryFile,
    previewImage,
    dropzoneToogle,
    sendButtonToogle,
    sendingToChatbot,
    userMessage,
    textAreaValue,
    isVideoStreamingReady,
    errorVideo,
    currentApiMessageChunk,
    currentApiMessageTranscriptFull,
  } = state;

  const conversationRef = useRef([]);

  useEffect(() => {
    conversationRef.current = conversation;
  }, [conversation]);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const response = await getChatMessages(chatbotId, conversationId);
        console.log(response);
        console.log(response.message);
        console.log(response.success);
        console.log(response.payload[0].FileModels[0]);

        if (response.success) {
          dispatch({ type: "SET_CONVERSATION", payload: response.payload });
        } else if (
          !response.success &&
          response.message === "No messages found"
        ) {
          dispatch({
            type: "SET_INFORMATION",
            payload: response.message,
          });
        }
      } catch (error) {
        dispatch({
          type: "SET_ERROR",
          payload: "An error occurred.",
        });
      }
    };

    const initConverstaion = async () => {
      const savedNewConversation = await sessionStorage.getItem(
        "savedNewConversation"
      ); // Await the Promise
      if (isNewConversation) {
        dispatch({ type: "RESET_STATE" });
        const uuid = uuidv4();

        dispatch({ type: "SET_CHATBOT_FLOW_ID", payload: uuid });

        if (chatbotDetails?.url) {
          const flowID = splitter(chatbotDetails.url, "prediction/", 1);
          dispatch({ type: "SET_FLOWISE_CHATBOT_ID", payload: flowID });
        }
        return;
      }

      if (savedNewConversation === null) {
        dispatch({ type: "RESET_STATE" });
        if (chatbotDetails?.url) {
          const flowID = splitter(chatbotDetails.url, "prediction/", 1);
          dispatch({ type: "SET_FLOWISE_CHATBOT_ID", payload: flowID });
        }

        if (sessionFlowiseID) {
          dispatch({ type: "SET_CHATBOT_FLOW_ID", payload: sessionFlowiseID });
        }
        fetchConversation();
      }
    };
    initConverstaion();
  }, [
    chatbotId,
    conversationId,
    isNewConversation,
    chatbotDetails,
    chatbotFlowiseID,
  ]);

  async function sendMessageWithFile(fileBase64String, temporaryFile) {
    //console.log('Temp file is: ', temporaryFile)
    const file = {
      //data: atob(fileBase64String.split(',')[1]), // Decode base64 string to raw content
      data: fileBase64String,
      type: "file", // Match Flowise expectations
      name: temporaryFile?.name, // File name
      mime: temporaryFile?.type, // MIME type
    };
    const response = await client.createPrediction({
      chatflowId: chatbotFlowID,
      question: "Describe image please",
      overrideConfig: {
        sessionId: "myTestChat4",
      },
      streaming: true, // Enable streaming response
      uploads: [file],
    });

    for await (const chunk of response) {
      console.log(chunk.data); // Streamed tokens
    }
  }

  const sendMessageToFlowiseNoFiles = async (message, isFromVideo = false) => {
    return new Promise(async (resolve, reject) => {
      const messageContent = isFromVideo ? message.content : message;
      //set user message
      const newUserMessage = {
        sender_type: "userMessage",
        message: messageContent,
      };
      dispatch({ type: "SET_USER_MESSAGE", payload: messageContent });
      // Update conversation with user message first
      // Use conversationRef to get the latest state
      const currentConversation = conversationRef.current;
      const updatedConversation = currentConversation
        ? [...currentConversation, newUserMessage]
        : [newUserMessage];
      dispatch({
        type: "SET_CONVERSATION",
        payload: updatedConversation,
      });
      try {
        dispatch({ type: "SET_CHATBOT_TYPING", payload: true });
        const response = await client.createPrediction({
          chatflowId: chatbotFlowiseID,
          question: messageContent,
          overrideConfig: {
            sessionId: chatbotFlowID,
          },
          streaming: true, // Enable streaming response
        });
        let fullResponse = "";

        let sentenceBuffer = ""; // Buffer to accumulate the sentence
        const exclusions = [
          "etc.",
          "e.g.",
          "i.e.",
          "@",
          "gmail.com",
          "hotmail.com",
        ]; // Add more as needed
        for await (const chunk of response) {
          const chunkMessage =
            chunk.data && chunk.event === "token" ? chunk.data : "";
          fullResponse += chunkMessage;

          //video chat - feed the response from llm
          if (chunkMessage && videoToogle) {
            if (chunkMessage) {
              dispatch({
                type: "SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL",
                payload: fullResponse,
              });
            }
            sentenceBuffer += chunkMessage; // Add chunkMessage to the buffer
            // Check if the sentence ends with a sentence-ending punctuation
            const trimmedBuffer = sentenceBuffer.trim();
            const lastChar = trimmedBuffer.slice(-1);
            if ([".", "?", "!"].includes(lastChar)) {
              // Check for exclusions
              let isException = false;
              for (const exclusion of exclusions) {
                if (
                  trimmedBuffer
                    .toLowerCase()
                    .endsWith(exclusion.toLowerCase()) ||
                  trimmedBuffer.toLowerCase().includes("@") // For email handling
                ) {
                  isException = true;
                  break;
                }
              }

              if (!isException) {
                // Send the completed sentence
                // Send the sentence to the avatar if handleSpeak is registered
                if (handleSpeakRef.current) {
                  await handleSpeakRef.current(trimmedBuffer);
                }
                // Reset the sentence buffer for the next sentence
                sentenceBuffer = "";
              }
            }
            if (chunk.event === "end" && chunk.data === "[DONE]") {
              const newApiMessage = {
                sender_type: "apiMessage",
                message: fullResponse,
              };
              // Get latest conversation and add API message
              const finalConversation = [...updatedConversation, newApiMessage];

              dispatch({
                type: "SET_CONVERSATION",
                payload: finalConversation,
              });
              // Clear the streaming text
              dispatch({
                type: "SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL",
                payload: null,
              });
              resolve(finalConversation); // Resolve with final conversation
            }
          } else {
            if (chunkMessage) {
              dispatch({
                type: "SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL",
                payload: fullResponse,
              });
            }
            if (chunk.event === "end" && chunk.data === "[DONE]") {
              const newApiMessage = {
                sender_type: "apiMessage",
                message: fullResponse,
              };
              // Get latest conversation and add API message
              const finalConversation = [...updatedConversation, newApiMessage];

              dispatch({
                type: "SET_CONVERSATION",
                payload: finalConversation,
              });
              // Clear the streaming text
              dispatch({
                type: "SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL",
                payload: null,
              });
              resolve(finalConversation); // Resolve with final conversation
            }
          }
        }
      } catch (error) {
        console.log("There was an error sending to LLM");
      } finally {
        dispatch({ type: "SET_CHATBOT_TYPING", payload: false });
      }
    });
  };

  const onFileAccepted = (file) => {
    handleFileUpload(file, (base64String, tempFile) =>
      dispatch({
        type: "SET_FILE",
        payload: { fileBase64String: base64String, temporaryFile: tempFile },
      })
    );
  };

  //it is not used as of now but iwill be when I have ready the backend
  const handleSendMessage = async (message = "") => {
    dispatch({ type: "SET_SEND_BUTTON_TOGGLE", payload: false });

    if (textAreaValue.trim()) {
      const updatedConverstaion = await sendMessageToFlowiseNoFiles(
        textAreaValue,
        false
      );
      const tokens = { in: 10, out: 20, total: 30 };

      const lastItem = updatedConverstaion[updatedConverstaion.length - 1]; // Last item
      const secondLastItem =
        updatedConverstaion[updatedConverstaion.length - 2]; // Second to last item
      const lastTwo = [secondLastItem, lastItem];

      const formData = new FormData();
      formData.append("chatMessages", JSON.stringify({ messages: lastTwo }));

      formData.append("tokensChat", JSON.stringify(tokens));
      if (isNewConversation) {
        //send first user's message to title geny
        let title;
        try {
          const message = `user message: ${secondLastItem.message}; chatbot message: ${lastItem.message}`;
          console.log("Message for title: ", message);
          const res = await generateTitle({
            question: `user message: ${secondLastItem.message}; chatbot message: ${lastItem.message}`,
          });
          title = res.text; // Use the generated title
        } catch (generateTitleError) {
          // Fallback to manual title generation if generateTitle fails
          // Get the current date
          const today = new Date();

          // Extract the day, month, and year
          const day = today.getDate().toString().padStart(2, "0"); // Ensures two digits
          const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
          const year = today.getFullYear();

          // Format the date as DD/MM/YYYY
          const formattedDate = `${day}/${month}/${year}`;
          title = `New Chat: ${formattedDate}`;
        }
        formData.append("chatBotId", chatBotId);
        formData.append("title", title);
        formData.append("session_id", chatbotFlowID);

        try {
          const response = await createNewChat(formData);
          if (response.data.success) {
            //onConversationSaved(response.payload.conversationId)
            sessionStorage.setItem("savedNewConversation", true);
            navigate(
              `/chatbot-dashboard/${chatBotId}/${response.data.payload[0]?.conversation_id}`,
              { replace: true }
            );
          }
        } catch (error) {
          dispatch({ type: "SET_ERROR", payload: error.message });
        }
      } else {
        formData.append("conversationId", conversationId);
        try {
          const response = await updateChatMessages(formData);

          if (response.data.success) {
            console.log("UPDATED CONVERSATION: ", response);
          }
        } catch (error) {
          dispatch({ type: "SET_ERROR", payload: error.message });
        }
      }
    }
    dispatch({ type: "SET_TEXT_AREA_VALUE", payload: "" });
    dispatch({ type: "SET_SEND_BUTTON_TOGGLE", payload: true });
  };

  // handlePromptChange updates what we're typing into the chat.
  const handlePromptChange = (event) => {
    const textarea = event.target;
    console.log("Textareais: ", event.target.value.length);
    textarea.style.height = "5rem"; // Reset to original height
    textarea.style.height = `${Math.min(
      textarea.scrollHeight,
      7.5 * parseFloat(getComputedStyle(textarea).fontSize)
    )}px`; // Adjust height based on content
    dispatch({ type: "SET_TEXT_AREA_VALUE", payload: event.target.value });
    dispatch({
      type: "SET_SEND_BUTTON_TOGGLE",
      payload: event.target.value.length > 0 && !sendingToChatbot,
    });
  };

  useEffect(() => {
    if (newMessagesContainerRef.current) {
      console.log("I SHOULD SCROLL TO THE BOTTOM - new messages ");
      const messagesContainer = newMessagesContainerRef.current;
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [conversation, chatbotTyping]);

  // Scroll to bottom with cleanup
  useEffect(() => {
    const messagesContainer = messagesContainerRef.current;
    if (messagesContainer) {
      const scrollToBottom = () => {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      };
      scrollToBottom();
    }
  }, [conversation, chatbotTyping]);

  useEffect(() => {
    if (messageTextAreaRef.current) {
      if (textAreaValue === "") {
        messageTextAreaRef.current.style.height = "5rem"; // Reset to original height
      } else {
        messageTextAreaRef.current.style.height = "auto";
        messageTextAreaRef.current.style.height = `${messageTextAreaRef.current.scrollHeight}px`;
      }
    }
  }, [textAreaValue]);

  // Clean up file preview URLs
  useEffect(() => {
    let objectURL = null;
    if (temporaryFile) {
      objectURL = URL.createObjectURL(temporaryFile);
      dispatch({ type: "SET_PREVIEW_IMAGE", payload: objectURL });
    }
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [temporaryFile]);

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter', the Shift key is not held down, and not currently sending a message
    if (event.key === "Enter" && !event.shiftKey && sendButtonToogle) {
      event.preventDefault(); // Prevents the default action (new line)
      // Call the function to send the message
      handleSendMessage();
    }
  };

  //look for video switch change and run only if switch is on

  useEffect(() => {}, [videoToogle]);

  const handleToogleLoading = (isLoadingVideo) => {
    dispatch({ type: "SET_VIDEO_STREAMING_READY", payload: isLoadingVideo });
  };

  const handleErrorVideo = (error) => {
    dispatch({ type: "SET_VIDEO_ERROR", payload: error });
  };

  const handleTranscriptReceivedFromDeepgram = async (transcript) => {
    if (transcript !== null || transcript !== "") {
      //send to LLM
      const updatedConverstaion = await sendMessageToFlowiseNoFiles(
        transcript,
        true
      );
      const shouldCreateNew =
        !currentConversationIdRef.current ||
        currentConversationIdRef.current === "new-conversation";
      const tokens = { in: 10, out: 20, total: 30 };

      const lastItem = updatedConverstaion[updatedConverstaion.length - 1]; // Last item
      const secondLastItem =
        updatedConverstaion[updatedConverstaion.length - 2]; // Second to last item
      const lastTwo = [secondLastItem, lastItem];

      const formData = new FormData();
      formData.append("chatMessages", JSON.stringify({ messages: lastTwo }));

      formData.append("tokensChat", JSON.stringify(tokens));
      if (shouldCreateNew) {
        let title = "";
        try {
          //send first user's message to title geny
          try {
            const message = `user message: ${secondLastItem.message}; chatbot message: ${lastItem.message}`;
            console.log("Message for title: ", message);
            const res = await generateTitle({
              question: `user message: ${secondLastItem.message}; chatbot message: ${lastItem.message}`,
            });
            title = res.text; // Use the generated title
          } catch (generateTitleError) {
            // Fallback to manual title generation if generateTitle fails
            // Get the current date
            const today = new Date();

            // Extract the day, month, and year
            const day = today.getDate().toString().padStart(2, "0"); // Ensures two digits
            const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
            const year = today.getFullYear();

            // Format the date as DD/MM/YYYY
            const formattedDate = `${day}/${month}/${year}`;
            title = `New Chat: ${formattedDate}`;
          }
        } catch (error) {
          dispatch({ type: "SET_ERROR", payload: error.message });
        }
        formData.append("chatBotId", chatBotId);
        formData.append("title", title);
        formData.append("session_id", chatbotFlowID);
        try {
          const response = await createNewChat(formData);
          if (response.data.success) {
            //onConversationSaved(response.payload.conversationId)
            sessionStorage.setItem("savedNewConversation", true);
            navigate(
              `/chatbot-dashboard/${chatBotId}/${response.data.payload[0]?.conversation_id}`,
              { replace: true }
            );
          }
        } catch (error) {
          dispatch({ type: "SET_ERROR", payload: error.message });
        }
      } else {
        // Handle normal message sending for existing conversations
        formData.append("conversationId", currentConversationIdRef.current);
        try {
          const response = await updateChatMessages(formData);

          if (response.data.success) {
            console.log("VIDEO - UPDATED CONVERSATION: ", response);
          }
        } catch (error) {
          dispatch({ type: "SET_ERROR", payload: error.message });
        }
      }
    } else {
      console.log("The transcript has issue");
    }
  };

  const renderMessageContent = (message) => (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => (
          <a
            {...props}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          />
        ),
      }}
    >
      {message.message}
    </ReactMarkdown>
  );

  const renderFileModels = (message) => {
    if (message.sender_type === "chatbot") {
      return message.FileModels?.length > 0 && <div>IMAGE PRESENT</div>;
    } else {
      return (
        <>
          {message.FileModels?.length > 0 &&
            message.FileModels.map((file, idx) => (
              <ImageContainer
                key={idx}
                fileDetails={file}
                onImageLoad={() => console.log("Images loaded")}
              />
            ))}
          {message.hasOwnProperty("file") && message.file !== null && (
            <img
              src={previewImage}
              alt="user uploaded file"
              style={{ maxWidth: "30%" }}
            />
          )}
        </>
      );
    }
  };

  const renderMessages = () => (
    <div ref={newMessagesContainerRef} className={styles.newMessageContainer}>
      {Array.isArray(conversation) &&
        conversation.map((message, index) => {
          const isChatbot = message.sender_type === "apiMessage";
          const avatarSrc = isChatbot ? imagePathChatbot : imagePathUser;
          const messageClass = isChatbot
            ? styles.chatbotMessage
            : styles.userMessage;

          return (
            <div key={index} className={messageClass}>
              <img className={styles.avatar} alt="avatar" src={avatarSrc} />
              <span className={styles.message}>
                {renderMessageContent(message)}
                {renderFileModels(message)}
              </span>
            </div>
          );
        })}
      {chatbotTyping && (
        <div className={styles.chatbotMessage}>
          <img className={styles.avatar} alt="avatar" src={imagePathChatbot} />
          <span className={styles.message}>
            {/* Replace the dots with the streamed text */}
            {currentApiMessageTranscriptFull
              ? currentApiMessageTranscriptFull
              : "..."}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.chatWindow}>
      {isLoading && conversationId ? (
        <div style={{ color: "white" }}>Loading</div>
      ) : (
        <div
          ref={messagesContainerRef}
          className={`${styles.messagesContainer} `}
        >
          <>
            {error !== "" ? (
              <ErrorCard
                message="An unexpected error occurred while retrieving the previous chat messages. Please try again later."
                to={`/chatbot-dashboard/${chatbotId}/new-conversation`}
                name="OK"
              />
            ) : (
              <>
                {errorVideo !== "" && (
                  <ErrorCard
                    message="There was an issue starting the video conversation. Please try again later"
                    name="OK"
                    handleBtnClick={() => {
                      handleToogleLoading(false);
                      handleErrorVideo("");
                    }}
                  />
                )}
                <div className={styles.parentContainer}>
                  <span className={styles.chatbotTitle}>
                    {chatbotDetails.title}
                  </span>
                  {chatbotDetails.streaming && (
                    <VideoSwitch
                      checked={videoToogle}
                      onChange={() => {
                        console.log("Video toogle is: ", videoToogle);
                        dispatch({
                          type: "SET_VIDEO_TOOGLE",
                          payload: !videoToogle,
                        });
                      }}
                    />
                  )}
                </div>
                {chatbotDetails.streaming && videoToogle ? (
                  <>
                    <div className={styles.loadingContainer}>
                      {" "}
                      {!isVideoStreamingReady && (
                        <div className={styles.overlay}>
                          <LoadingSpinner />
                          <p className={styles.chatbotTitle}>Connecting</p>
                        </div>
                      )}
                      <div className={styles.videoContainer}>
                        {" "}
                        <InteractiveAvatar
                          initiateSession={videoToogle}
                          avatarId={chatbotDetails.avatar_heygen_id}
                          toggleLoadingVIdeo={handleToogleLoading}
                          error={handleErrorVideo}
                          handleMessageFromDeepgram={
                            handleTranscriptReceivedFromDeepgram
                          }
                          onRegisterHandleSpeak={registerHandleSpeak}
                        />
                      </div>
                    </div>
                    {renderMessages()}
                  </>
                ) : (
                  renderMessages()
                )}
              </>
            )}
          </>
          {dropzoneToogle && <DropZoneInput onFileAccepted={onFileAccepted} />}
          {temporaryFile && (
            <p style={{ color: "white" }}>File Ready: {temporaryFile.name}</p>
          )}

          {!videoToogle && (
            <div className={styles.footer}>
              {videoToogle && chatbotDetails.streaming && (
                <div className={styles.overlay2} />
              )}
              <div>
                <span
                  onClick={() =>
                    dispatch({
                      type: "SET_DROPZONE_TOOGLE",
                      payload: !dropzoneToogle,
                    })
                  }
                  className={`material-symbols-outlined ${styles.materialSymbolsOutlined}`}
                >
                  attach_file
                </span>
              </div>
              <textarea
                ref={messageTextAreaRef}
                className={styles.inputField}
                onChange={handlePromptChange}
                value={textAreaValue}
                placeholder="Type here..."
                onKeyDown={handleKeyDown}
              />

              <SendButton
                onClick={handleSendMessage}
                disabled={!sendButtonToogle}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

//export default ChatWindow

export default React.memo(ChatWindow);

// async function query(fileBase64String, temporaryFile) {
//   const response = await fetch(
//     'https://chatbot.humanasset.com/api/v1/prediction/024415fa-3f43-43e9-a5f6-8d411142741a',
//     {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         question: 'Who is the magician in the filke attached?',
//         chatId: 'reguralHTTPRequest',
//         uploads: [
//           {
//             data: fileBase64String,
//             type: 'file:full', // Match Flowise expectations
//             name: temporaryFile?.name, // File name
//             mime: 'application/pdf',
//           },
//         ],
//       }),
//     },
//   )
//   const result = await response.json()
//   return result
//}

{
  /* <video
                          className={styles.video}
                          ref={videoStreamingRef}
                          width="400"
                          height="400"
                          autoPlay
                          playsInline
                          loop
                          src={''}
                          muted // Add this if you need to mute the video for autoplay to work in some browsers
                        ></video> */
}
