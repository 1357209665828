import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import styles from './../../styles/newChatting/ChatPanel.module.css'
import {
  getUserConversations,
  deleteChat,
} from '../../services/API/ChatServices'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'

const ChatPanel = ({ className, handleSessionFlowiseID }) => {
  const { chatBotId, conversationId } = useParams()

  console.log('Conversation id is: ', conversationId)
  const [userConversationsWithChatbot, setUserConversaionsWithChatbot] =
    useState([])

  const [error, setError] = useState('')

  const navigate = useNavigate()

  const location = useLocation() // Use location to check the full URL
  // Determine if it's a "new conversation" by checking the URL
  const isNewConversation =
    location.pathname.endsWith('new-conversation') ||
    conversationId === 'new-conversation'

  useEffect(() => {
    console.log('I should run')
    const fetchUserConversations = async () => {
      try {
        if (chatBotId) {
          const response = await getUserConversations(chatBotId)
          if (response.success) {
            setUserConversaionsWithChatbot(response.payload.reverse())
          } else {
            //setInformation(response.message)
            //console.log(response.message)
          }
        }
      } catch (error) {
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserConversations()
  }, [chatBotId, conversationId])

  const getLinkClassName = (conversationIdFromList) => {
    // Check if the current conversationId is in the URL
    const isActive = String(conversationIdFromList) === String(conversationId)
    return isActive ? `${styles.link} ${styles.activeLink}` : styles.link
  }

  const handleSelectConversation = (id, sessionFlowiseID) => {
    sessionStorage.removeItem('savedNewConversation')
    console.log('CHAT PANEL: FLOWISE ID : ', sessionFlowiseID)
    handleSessionFlowiseID(sessionFlowiseID)
    navigate(`/chatbot-dashboard/${chatBotId}/${id}`)
  }

  const handleDelete = async (conversationId) => {
    try {
      const response = await deleteChat(conversationId)
      if (
        response.success &&
        response.message === 'Chat deleted successfully'
      ) {
        //chat deleted successfully
        if (location.pathname.endsWith(`/${chatBotId}/${conversationId}`)) {
          const sessionStorageConversation = sessionStorage.getItem(
            'savedNewConversation',
          )
          if (
            sessionStorageConversation?.id &&
            sessionStorageConversation.id === conversationId
          ) {
            sessionStorage.removeItem('savedNewConversation')
          }

          //this means that delted conv is the one which is currently open
          //we need to redirect user to new chat after deleting
          setUserConversaionsWithChatbot((prevConversations) =>
            prevConversations.filter(
              (conv) => conv.conversation_id !== conversationId,
            ),
          )
          navigate(`/chatbot-dashboard/${chatBotId}/new-conversation`)
        } else {
          //it is different saved chat or it is a new chat
          //just to delete from the converstionlist

          setUserConversaionsWithChatbot((prevConversations) =>
            prevConversations.filter(
              (conv) => conv.conversation_id !== conversationId,
            ),
          )
        }
      } else {
        //problem
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <div className={`${styles.panel} ${className}`}>
      {/* Link to start a new chat */}
      <div className={`${styles.conversation_item} `}>
        <button
          className={
            isNewConversation
              ? `${styles.link} ${styles.activeLink} `
              : `${styles.link} `
          }
          onClick={() => handleSelectConversation('new-conversation')}
        >
          <div className={styles.textContainer}>New Chat</div>
        </button>
        <EditNoteIcon className={styles.icon} />
      </div>
      <hr className={styles.line} />
      <div className={styles.conversationsList}>
        {userConversationsWithChatbot.map((conv) => (
          <div
            key={conv.conversation_id}
            className={`${styles.conversation_item} `}
          >
            <button
              key={conv.conversation_id}
              onClick={() =>
                handleSelectConversation(conv.conversation_id, conv.session_id)
              }
              className={`${styles.conversationTitle} ${getLinkClassName(
                conv.conversation_id,
              )}`}
            >
              <div className={styles.textContainer}>{conv.title}</div>
            </button>
            <div
              className={`${styles.delete_button} ${styles.icon}`}
              onClick={() => handleDelete(conv.conversation_id)}
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
        {/* <Link
          className={styles.arrowBack}
          to="/chatbot-dashboard"
          onClick={(e) => {
            e.preventDefault()
            handleLinkClick(`/chatbot-dashboard`)
          }}
        >
          <ArrowBackIcon />
        </Link> */}
      </div>
    </div>
  )
}
export default React.memo(ChatPanel)
